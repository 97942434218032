<template>
    <div class="max-box">
        <div class="header-box">
            <div class="header-left">
                <span>年报表</span>
            </div>
            <div>
                <a-icon
                    type="vertical-align-bottom"
                    title="导出"
                    @click="down"
                />
            </div>
        </div>
        <div class="content-box" style="padding: 10px">
            <div style="display: flex;align-items: center;margin-bottom: 10px">
                <!--搜索查询-->
                <a-input v-model="date" placeholder="输入年份" style="width: 200px"/>
                <a-button type="primary" size="small" @click="getDataList" style="margin-left: 10px">查询</a-button>
            </div>
            <div class="table-container" style="position: relative; overflow: hidden">
                <a-table :dataSource="dataSource" :columns="finalColumns" :scroll="{ x: 2600, y: yHeight }"/>
            </div>
        </div>
    </div>
</template>
<script>

import {downloadMonth, downloadYear, exportDown, queryReportYear} from "@/api/report";
import moment from "moment";

export default {
    data() {
        return {
            date: moment().format("YYYY"),
            yHeight:
                document.documentElement.clientHeight -
                document.documentElement.clientHeight / 3.5,
            dataSource: [],
            dayLabels: [],
            total: 0,
        };
    },
    methods: {
        getDataList() {
            console.log(this.dataSource)
            queryReportYear({year: this.date}).then(res => {
                console.log(res)
                this.tableData = res.data
                // 初始化dayLabels以包含所有可能的日期标签
                const maxDay = Math.max(...this.tableData.map(item => item.powers.length));
                this.dayLabels = Array.from({length: maxDay}, (_, i) => `${i + 1}月`);
                // 构建dataSource
                this.dataSource = this.tableData.map((item, index) => {
                    const powerEntries = {};
                    item.powers.forEach((power, i) => {
                        powerEntries[`power${i + 1}`] = power;
                    });
                    return {
                        ...item,
                        key: index, // 确保每个数据项都有一个唯一的key属性
                        ...powerEntries
                    };
                });
            })
        },
        changeDate(e) {
            this.date = moment(e._d).format("YYYY-MM");
        },
        down(){
            downloadYear({year: this.date}).then(res=>{
                exportDown(res, "年报表");
            })
        }
    },
    mounted() {
        this.getDataList()
    },
    beforeDestroy() {
    },
    computed: {
        finalColumns() {
            // 创建基本列
            let columns = [
                {title: '电站名称', dataIndex: 'stationName', key: 'stationName', width: 200},
                {title: '逆变器序列号', dataIndex: 'inverterSn', key: 'inverterSn', width: 160},
                {title: '功率', dataIndex: 'power', key: 'power', width: 80}
            ];
            // 动态添加每日发电量列
            this.dayLabels.forEach((label, index) => {
                columns.push({
                    title: label,
                    dataIndex: `power${index + 1}`,
                    key: `power${index + 1}`,
                    width: 100
                });
            });
            return columns;
        }
    }
};
</script>
<style lang="less" scoped>
.max-box {
    width: 100%;
    padding-top: 16px;
    height: 100%;
    padding-right: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    .header-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        // padding-right: 20px;
        margin-bottom: 16px;

        .header-left {
            span {
                font-size: 18px;
                color: #252b3a;
            }

            i {
                font-size: 20px;
            }
        }

        .search-box {
            width: 240px;
            height: 32px;

            /deep/ .ant-input {
                font-size: 12px;
            }
        }

        .add-btn {
            font-size: 12px;
        }
    }

    .content-box {
        background: #fff;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        // tabs
        .tarbar-box {
            padding-bottom: 12px;
            position: relative;
            padding-right: 16px;
            width: 100%;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            display: flex;
            justify-content: space-between;
            align-items: center;

            .tabs {
                display: flex;
                padding-left: 16px;
                align-items: center;
                box-sizing: border-box;

                .tab-one {
                    padding: 10px 0 8px 0;
                    margin-right: 24px;
                    font-size: 12px;
                    cursor: pointer;
                    color: #515561;
                    box-sizing: border-box;
                }

                .open-choose {
                    margin-top: 3px;
                    cursor: pointer;
                    padding-left: 16px;
                    border-left: 1px solid rgba(0, 0, 0, 0.1);

                    &:hover {
                        color: #048fff;
                    }

                    span {
                        margin-right: 1px;
                    }

                    transition: all 1s;
                }

                .active-choose {
                    color: #048fff;

                    .icon-down {
                        transform: rotate(180deg);
                    }
                }

                .active-tab {
                    color: #048fff;
                    border-bottom: 2px solid #048fff;
                }
            }

            .right-box {
                font-size: 16px;

                i {
                    cursor: pointer;

                    &:hover {
                        color: #048fff;
                    }
                }

                .icon-left {
                    margin-left: 20px;
                    transition: all 0.4s;

                    &:hover {
                        transform: rotate(90deg);
                    }
                }
            }
        }

        .list-box {
            flex-grow: 1;

            .weather-box {
                height: 100%;
                overflow-y: auto;
                display: grid;
                padding: 16px;
                box-sizing: border-box;
                grid-template-columns: repeat(3, 1fr); /* 三列等宽 */
                grid-auto-rows: 60px; /* 自动调整行高度 */
                grid-gap: 10px; /* 列间距和行间距，可根据需要调整 */

                .one-msg {
                    font-size: 12px;
                    color: #00000073;

                    .one-detail {
                        color: #515561;
                    }
                }
            }
        }

        .name-box {
            cursor: pointer;
            width: 100%;
        }

        // 操作样式
        .operation-box {
            width: 100%;
            padding-left: 15px;
            font-size: 16px;

            i {
                cursor: pointer;
                transition: all 0.5s;

                &:hover {
                    color: #048fff;
                }
            }

            .icon-left {
                margin-left: 30px;
            }
        }
    }
}

// 抽屉内容
.add-power {
    transform: translate(0) !important;

    .head-box {
        .name-box {
            display: flex;
            justify-content: space-between;

            span {
                font-size: 18px;
                color: #252b3a;
            }

            .operation-right {
                display: flex;
                align-items: center;
                font-size: 16px;
                color: rgba(0, 0, 0, 0.45);

                .mr_16 {
                    margin-right: 16px;
                    transition: all 0.5s;
                    cursor: pointer;

                    &:hover {
                        color: #048fff;
                    }
                }

                .close-box {
                    background: #ff7875;
                    cursor: pointer;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 28px;
                    height: 28px;
                    font-size: 16px;
                    color: #fff;
                    border-radius: 50%;

                    &:hover {
                        i {
                            transform: rotate(90deg);
                        }
                    }

                    i {
                        transition: all 0.3s;
                    }
                }
            }
        }

        .status-box {
            display: flex;
            justify-content: space-between;
            margin-top: 10px;

            .left-box {
                display: flex;
                align-items: center;

                i {
                    font-size: 12px;
                }

                span {
                    font-size: 14px;
                    color: #515561;
                    margin-left: 6px;
                }
            }

            .right-time {
                font-size: 12px;
                color: #515561;
            }
        }
    }

    .drawer-tab-box {
        padding: 0 8px;
        margin-top: 16px;

        .all-tabs {
            display: flex;

            span {
                padding: 12px 0 8px;
                margin-right: 24px;
                font-size: 12px;
                color: #00000073;
                cursor: pointer;
            }

            .active-drawer-tab {
                color: #048fff;
                border-bottom: 2px solid #048fff;
                font-weight: 700;
            }
        }
    }

    .content-box {
        padding: 0 16px;
        box-sizing: border-box;
    }
}
</style>
